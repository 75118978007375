/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from 'axios';
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import formatDistance from 'date-fns/formatDistance';
import CommentList from "../components/CommentList";
import CommentForm from "../components/CommentForm";
import default_image from '../logo/default_user.jpg';
import React, { useCallback } from 'react';
import ReactPlayer from 'react-player'
// import ReactMarkdown from "react-markdown";
import './markdownStyles.css'
import { Helmet } from 'react-helmet';

const PostDetail = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState({});
    const [comments, setComments] = useState({});
    const [toggleForm, setToggleForm] = useState(false);
    const { user } = useSelector((state) => state.auth);


    const isVideo = useCallback((url) => {
        const videoExtensions = ['.mp4', '.webm', '.ogg'];
        return videoExtensions.some((ext) => url && url.endsWith(ext));
    }, []);

    useEffect(() => {
        if (!slug) { return; }

        // Fetch post data based on slug
        axios.get(`/api/posts/post/${slug}`)
            .then(res => {
                // Once post data is fetched, set the post state
                const fetchedPost = res.data[0];
                setPost(fetchedPost);

                // Now that we have post._id, fetch comments for this post
                return axios.get(`/api/comments/post/${fetchedPost._id}`);
            })
            .then(res => {
                // Set comments state with fetched comments data
                setComments(res.data);
            })
            .catch(err => {
                // Handle any errors that occur during the fetch operations
                toast.error(err.message || "An error occurred");
            });
    }, [slug]);

    const toggleCommentForm = () => {
        setToggleForm(!toggleForm);
    };

    const refreshCommentData = async() => {
        await axios
            .get(`/api/comments/post/${post._id}`)
            .then(res => {
                setComments(res.data)
            })
            .catch(err => toast.error(err));
    }

    const addMyFavoritePost = async () => {
        if (!post._id || !user) {
            toast.error('Post Id and user id are required');
        } else {
            const data = post._id;
            const config = {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            };
            await axios
                .post('/api/favoriteposts/', data, config)
                .then(res => {
                    toast.success('The task has been done. Please visit your favorire posts page.');
                    return res.data
                })
                .catch(err =>
                {
                    if(err.response && err.response.status===400) {
                        toast.error('The post already exists in your favorite post collection.');
                    };
                });
        }
    };

    // Get author image
    const authorImage = post.author && post.author.image ? post.author.image : default_image;
    const videoUrl = 'https://www.youtube.com/watch?v=_Vk9UXZp2JY&t=168s'

    return (
        <Container fluid>
            <Helmet>
                <title>{post.title ? `${post.title} - Limbonaut` : 'Post Detail - Limbonaut'}</title>
                <link rel="canonical" href={`https://www.limbonaut.com/postDetail/${post.country}`} />
                <meta
                    name="description"
                    content={post.content ? `${post.content.substring(0, 150)}...` : "Explore detailed post content on Limbonaut."}
                />
                <link rel="alternate" hrefLang="en" href={`https://www.limbonaut.com/postDetail/${post.country}`} />
                <link rel="alternate" hrefLang="x-default" href={`https://www.limbonaut.com/postDetail/${post.country}`} />
            </Helmet>


            <div className='title bottom__line'>{post.title}</div>
            <Row>
                <Col>

                    <Card className='mb-2'
                          style={{
                              backgroundColor: '#ffffff',
                              boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                              borderRadius: '15px',
                              padding:'0px'
                          }}>
                        {post.image ? (
                            isVideo(post.image) ? (
                                <div className="banner">
                                    <ReactPlayer
                                        className="w-100"
                                        url={post.image}
                                        controls
                                        style={{
                                            borderRadius: '10px',
                                            maxHeight: '500px',
                                            objectFit:'cover'
                                        }}

                                    />
                                </div>
                            ) : (
                                <Card.Img
                                    src={post.image}
                                    alt={post.title}
                                    style={{
                                        borderRadius: '10px',
                                        maxHeight: '500px',
                                        objectFit:'cover'
                                    }}

                                />
                            )
                        ) : ''}
                        <Card.Body>
                            <Card.Text
                                style={{
                                    color: '#050505'
                            }}
                            >
                                <img src={authorImage} alt={post.author ? `${post.author.firstName} ${post.author.lastName}` : 'Author image'} className='author-small-image' />
                                &nbsp;
                                <a
                                    href={post.city}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        marginRight: '10px',
                                        color: '#15a2fa',  // Change to your desired color
                                        fontWeight: 'bold',
                                        textDecoration: 'none',  // Removes the default underline
                                        transition: 'all 0.3s ease',  // Smooth transition effect
                                        fontSize: '18px'
                                    }}
                                    onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}  // Underline on hover
                                    onMouseLeave={(e) => e.target.style.textDecoration = 'none'}  // Remove underline after hover
                                >
                                    {post.city}
                                </a>
                                |&nbsp;
                                {post.author ? ` ${post.author.firstName} ${post.author.lastName}` : ''}
                                {post.updatedAt ? ` - Last modified: ${formatDistance(new Date(post.updatedAt), new Date())}` : ''}
                                &nbsp;|&nbsp;Slug: &nbsp;{post.country}
                            </Card.Text>
                        </Card.Body>
                    </Card>


                </Col>
                <Col>

                    {/*<Card.Text>*/}
                    {/*    {post.content}*/}
                    {/*</Card.Text>*/}
                    {post.airBnBPrice ? (
                        <a href={post.airBnBPrice} target="_blank" rel="noopener noreferrer">
                            <Button
                                variant="primary"
                                style={{
                                    backgroundColor: "#f0f2f5",
                                    color: "#15a2fa",
                                    borderColor: "#15a2fa",
                                    marginBottom: "16px",
                                    width:'30%'
                                }}
                            >
                                Visit Website
                            </Button>
                        </a>
                    ) : null}

                    <div
                        className="markdown-container"
                        style={{
                            backgroundColor:'#ffffff',
                            padding:'15px',
                            borderRadius:'15px',
                            marginBottom:'15px',
                            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                        }}
                        dangerouslySetInnerHTML={{ __html: post.content }}
                    ></div>

                    {user && !toggleForm ?
                        (<div className="mb-2">
                            <Button variant="outline-primary" type="submit" onClick={()=> toggleCommentForm()}>
                                Add New Comment
                            </Button>

                        </div>)
                        : ''
                    }

                    {post._id && (
                        <div className={toggleForm ? 'show' : 'hide'}>
                            <Card.Body>
                                <CommentForm postId={post._id} user={user} toggleCommentForm={toggleCommentForm} refreshCommentData={refreshCommentData} />
                            </Card.Body>
                        </div>
                    )}

                    <Card className='mb-2'
                          style={{
                              backgroundColor:'#ffffff',
                              padding:'15px',
                              borderRadius:'15px',
                              marginBottom:'15px',
                              boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                          }}


                    >
                        <Card.Body>
                            {comments && comments.length > 0 ? <CommentList comments = {comments} refreshCommentData = {refreshCommentData} /> : null}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Button variant="outline-primary" type="submit" onClick={()=> navigate('/')}>
                Back to Home Page
            </Button>{"  "}
            {user ?
                <Button variant="outline-primary" type="submit" onClick={addMyFavoritePost}>
                    Add to My Favorite Apps
                </Button>
                : ''
            }
        </Container>
    )
}

export default PostDetail
