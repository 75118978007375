/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player'
import {Card} from "react-bootstrap";


const isVideo = (url) => {
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    return videoExtensions.some((ext) => url.endsWith(ext));
};
const ImageSlideShow = ({ posts }) => {
    const imageList = posts.filter(post => post.image);
    return (

        <Carousel>
            {imageList.map((image) =>
                <Carousel.Item key = {image._id}>
                    <Link to={`/postDetail/${image.country}`}>
                        {isVideo(image.image) ? (<ReactPlayer className="w-100" url={image.image} controls />) : (<img src={image.image} alt={image.title} className="w-100" />)}
                        <Carousel.Caption>
                            <h3>{image.title}</h3>
                        </Carousel.Caption>
                    </Link>
                </Carousel.Item>
            )}
        </Carousel>

    )
}

ImageSlideShow.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ImageSlideShow
