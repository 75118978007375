import { useState, useEffect } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet';

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const { email, password } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      toast.error(message.error);
    }

    if (isSuccess || user) {
      // Jump to My Posts page
      navigate("/userHome");
    }

    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (!email || !password) {
      toast.error('Please enter your email and password!')
      return
    }

    const userData = {
      email,
      password,
    }

    dispatch(login(userData))

  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Container style={{ width: "400px" }}>
      <Helmet>
        <title>Login - Limbonaut</title>
        <meta name="description" content="Login to the application to access your account and manage your posts." />
      </Helmet>
      <section className='heading'>
        <h3>
          <FaSignInAlt /> Login
        </h3>
        <h4>Login and start posting posts</h4>
      </section>

      <section className='form'>
        <form onSubmit={onSubmit}>
          <div className='form-group'>
            <input
              type='email'
              className='form-control'
              id='email'
              name='email'
              value={email}
              placeholder='Enter your email'
              onChange={onChange}
              required
            />
          </div>
          <div className='form-group'>
            <input
              type='password'
              className='form-control'
              id='password'
              name='password'
              value={password}
              placeholder='Enter password'
              onChange={onChange}
              required
            />
          </div>

          <div className='form-group'>
            <button type='submit' className='btn btn-block'>
              Submit
            </button>
          </div>
        </form>
      </section>
    </Container>
  )
}

export default Login
